import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import WelcomeImage from '../../images/WelcomeImage';
import { renderToStaticMarkup } from 'react-dom/server';


const PREFIX = 'WelcomeModal';

const classes = {
    dialogTitle: `${PREFIX}-dialogTitle`,
    dialogContent: `${PREFIX}-dialogContent`,
    title: `${PREFIX}-title`,
    content: `${PREFIX}-content`,
    dialogActions: `${PREFIX}-dialogActions`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogTitle}`]: {
        width: theme.spacing(75),
        minWidth: theme.spacing(75),
        height: theme.spacing(40),
        minHeight: theme.spacing(40),
        backgroundImage: `url("data:image/svg+xml,${svgString}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0
    },
    [`& .${classes.dialogContent}`]: {
        padding: theme.spacing(1, 3, 3)
    },
    [`& .${classes.title}`]: {
        fontWeight: 550,
        padding: theme.spacing(1, 0)
    },
    [`& .${classes.content}`]: {
        color: theme.palette.text.secondary
    },
    [`& .${classes.dialogActions}`]: {
        padding: theme.spacing(2, 3)
    }
}));


const svgString = encodeURIComponent(renderToStaticMarkup(<WelcomeImage />));

const WelcomeModal = () => {

    const [isOpen, setIsOpen] = useState(true);

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <StyledDialog
            open={isOpen}
            onClose={onClose}
            maxWidth="md"
        >
            <DialogTitle className={classes.dialogTitle} />
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6" className={classes.title}>
                    Welcome To CityPass!
                </Typography>
                <Typography className={classes.content}>
                CityPass helps verify the employee's identity using two security questions<br />
                    In case the employee forgot the password of his account.
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    autoFocus
                    variant="contained"
                    onClick={onClose}
                    color="primary"
                >
                    Get Started
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default WelcomeModal;
