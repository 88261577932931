import React from "react";

const LoginPageBGImage = () => {
    return (
        <svg viewBox="0 0 1600 1058" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1222.24 169.064L1222.24 280.151L1111.21 280.151C1111.21 218.831 1160.92 169.064 1222.24 169.064Z" fill="#395CFF" />
            <path opacity="0.2" d="M667.094 391.238L778.123 391.238L778.123 502.325C716.803 502.325 667.094 452.558 667.094 391.238Z" fill="#395CFF" />
            <path d="M1333.27 613.412L1333.27 502.325L1444.3 502.325C1444.3 563.645 1394.59 613.412 1333.27 613.412Z" fill="#395CFF" />
            <g filter="url(#filter0_ddd)">
                <path d="M334.029 168.91L334.029 279.997L223 279.997C223 218.677 272.709 168.91 334.029 168.91Z" fill="#2948D5" />
            </g>
            <path opacity="0.6" d="M111.976 390.804L111.976 501.833L0.888804 501.833C0.888807 440.514 50.6557 390.804 111.976 390.804Z" fill="#21FCBE" />
            <path opacity="0.3" d="M334.058 725.029L334.058 836.058L222.971 836.058C222.971 774.738 272.738 725.029 334.058 725.029Z" fill="#21FCBE" />
            <path d="M1111.21 724.498L1000.18 724.498L1000.18 613.412C1061.5 613.412 1111.21 663.178 1111.21 724.498Z" fill="#395CFF" />
            <path d="M444.418 835.585L555.448 835.585L555.448 946.672C494.128 946.672 444.418 896.905 444.418 835.585Z" fill="#395CFF" />
            <path opacity="0.3" d="M889.769 947.134L778.739 947.134L778.74 836.047C840.059 836.047 889.769 885.814 889.769 947.134Z" fill="#21FCBE" />
            <ellipse cx="1055.7" cy="780.042" rx="55.5434" ry="55.5147" transform="rotate(90 1055.7 780.042)" fill="#395CFF" />
            <ellipse opacity="0.3" cx="167.153" cy="891.59" rx="55.5433" ry="55.5147" transform="rotate(90 167.153 891.59)" fill="#395CFF" />
            <g opacity="0.6" filter="url(#filter1_ddd)">
                <ellipse cx="1277.51" cy="1002.22" rx="55.5433" ry="55.5147" transform="rotate(90 1277.51 1002.22)" fill="#22FBBD" />
            </g>
            <ellipse opacity="0.1" cx="500.55" cy="668.955" rx="55.5434" ry="55.5146" transform="rotate(90 500.55 668.955)" fill="#21FCBE" />
            <path d="M889.152 57.9773L889.152 169.064L778.123 169.064C778.123 107.744 827.832 57.9773 889.152 57.9773Z" fill="#395CFF" />
            <g opacity="0.1" filter="url(#filter2_ddd)">
                <path d="M445.029 280.087L334 280.087L334 169L445.029 169L445.029 280.087Z" fill="#21F9BC" />
            </g>
            <g opacity="0.1" filter="url(#filter3_ddd)">
                <path d="M1111.52 279.843L1222.55 279.843L1222.55 390.929C1161.23 390.929 1111.52 341.162 1111.52 279.843Z" fill="#22FBBD" />
            </g>
            <g filter="url(#filter4_ddd)">
                <path d="M445.035 280.151L556.064 280.151L556.064 391.237C494.744 391.237 445.035 341.47 445.035 280.151Z" fill="#2C4EEC" />
            </g>
            <g filter="url(#filter5_ddd)">
                <path d="M334.005 169.064L334.005 57.9774L445.034 57.9774C445.034 119.297 395.325 169.064 334.005 169.064Z" fill="#2C4EEC" />
            </g>
            <g filter="url(#filter6_ddd)">
                <path d="M1000.18 391.238L1111.21 391.238L1111.21 502.324C1049.89 502.324 1000.18 452.557 1000.18 391.238Z" fill="#2C4EEC" />
            </g>
            <g filter="url(#filter7_ddd)">
                <path d="M778.123 613.412L889.152 613.412L889.152 724.498C827.832 724.498 778.123 674.731 778.123 613.412Z" fill="#2C4EEC" />
            </g>
            <g filter="url(#filter8_ddd)">
                <path d="M334.005 724.498L334.005 613.411L445.034 613.411C445.034 674.731 395.325 724.498 334.005 724.498Z" fill="#2C4EEC" />
            </g>
            <path opacity="0.1" d="M334.005 613.087L334.005 502L445.034 502C445.034 563.32 395.325 613.087 334.005 613.087Z" fill="#21FCBE" />
            <g filter="url(#filter9_ddd)">
                <path d="M556.064 1057.76L445.035 1057.76L445.035 946.672C506.354 946.672 556.064 996.439 556.064 1057.76Z" fill="#2C4EEC" />
            </g>
            <g filter="url(#filter10_ddd)">
                <ellipse cx="1277.75" cy="113.521" rx="55.5434" ry="55.5147" transform="rotate(90 1277.75 113.521)" fill="#2C4EEC" />
            </g>
            <g filter="url(#filter11_ddd)">
                <ellipse cx="833.637" cy="335.694" rx="55.5434" ry="55.5147" transform="rotate(90 833.637 335.694)" fill="#2C4EEC" />
            </g>
            <g filter="url(#filter12_ddd)">
                <path d="M1555.03 502.087L1444 502.087L1444 391C1505.32 391 1555.03 440.767 1555.03 502.087Z" fill="#2948D5" />
            </g>
            <g filter="url(#filter13_ddd)">
                <path d="M1000.18 169.064L889.152 169.064L889.152 57.9777C950.472 57.9777 1000.18 107.745 1000.18 169.064Z" fill="#2948D5" />
            </g>
            <g opacity="0.7" filter="url(#filter14_ddd)">
                <path d="M1111.21 613.412L1111.21 502.325L1222.24 502.325C1222.24 563.645 1172.53 613.412 1111.21 613.412Z" fill="#2948D5" />
            </g>
            <path opacity="0.3" d="M1444.3 613.411L1444.3 724.498L1333.27 724.498C1333.27 663.178 1382.98 613.411 1444.3 613.411Z" fill="#21FCBE" />
            <g filter="url(#filter15_ddd)">
                <path d="M778.123 835.584L778.123 724.498L889.152 724.498C889.152 785.817 839.443 835.584 778.123 835.584Z" fill="#2948D5" />
            </g>
            <g opacity="0.6" filter="url(#filter16_ddd)">
                <ellipse cx="1055.7" cy="335.694" rx="55.5434" ry="55.5147" transform="rotate(90 1055.7 335.694)" fill="#2948D5" />
            </g>
            <defs>
                <filter id="filter0_ddd" x="179" y="130.91" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.492188 0 0 0 0 0.507656 0 0 0 0 0.5625 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter1_ddd" x="1178" y="908.672" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter2_ddd" x="290" y="131" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter3_ddd" x="1067.52" y="241.843" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter4_ddd" x="401.035" y="242.151" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter5_ddd" x="290.005" y="19.9775" width="199.03" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter6_ddd" x="956.182" y="353.238" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter7_ddd" x="734.123" y="575.412" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter8_ddd" x="290.005" y="575.411" width="199.03" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter9_ddd" x="401.035" y="908.672" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter10_ddd" x="1159.24" y="0.977295" width="237.029" height="237.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="19" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="3" operator="dilate" in="SourceAlpha" result="effect2_dropShadow" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="7" operator="erode" in="SourceAlpha" result="effect3_dropShadow" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter11_ddd" x="715.123" y="223.151" width="237.029" height="237.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="19" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="3" operator="dilate" in="SourceAlpha" result="effect2_dropShadow" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="7" operator="erode" in="SourceAlpha" result="effect3_dropShadow" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter12_ddd" x="1400" y="353" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.492188 0 0 0 0 0.507656 0 0 0 0 0.5625 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter13_ddd" x="845.152" y="19.9778" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.492188 0 0 0 0 0.507656 0 0 0 0 0.5625 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter14_ddd" x="1067.21" y="464.325" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.492188 0 0 0 0 0.507656 0 0 0 0 0.5625 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter15_ddd" x="734.123" y="686.498" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.492188 0 0 0 0 0.507656 0 0 0 0 0.5625 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
                <filter id="filter16_ddd" x="956.182" y="242.151" width="199.029" height="208.087" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="22" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="23" />
                    <feGaussianBlur stdDeviation="18" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.492188 0 0 0 0 0.507656 0 0 0 0 0.5625 0 0 0 0.035 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="7" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    )
};

export default LoginPageBGImage;
