import React, { useEffect } from "react";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { Skeleton } from '@mui/material';
import CustomAvatar from "../custom/CuiAvatar";
import i18next from "i18next";
import { useTranslation } from 'react-i18next';
import { grey } from "@mui/material/colors";
import { Block, Close, Done } from "@mui/icons-material";


const AutenticateUserDialog = ({ open, onClose, onVerify, onNotVerify, user, answers, isVerifying, isNotVerifying }) => {

    const { t } = useTranslation();

    useEffect(() => {
        var l = ["en", "he"][user.languageId];
        i18next.changeLanguage(l || 'en');
    }, [user.languageId]);

    return (
        user &&
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (!(isVerifying || isNotVerifying) || reason !== 'backdropClick') {
                    onClose();
                }
            }}
            aria-labelledby="form-dialog-title"
            maxWidth={false}
        >
            <DialogTitle id="form-dialog-title">
                <div>
                    <Typography
                        variant='h6'
                        sx={{
                            fontWeight: 550
                        }}
                    >
                        Verify Employee Identity
                    </Typography>
                    <Typography>Ask the employee to answer the following questions:</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        disabled={isVerifying || isNotVerifying}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            {answers ?
                <>
                    <DialogContent
                        sx={{
                            display: "flex",
                            mt: 3
                        }}
                    >
                        <Stack
                            sx={theme => ({
                                width: theme.spacing(28),
                                alignItems: 'center',
                                color: grey[800],
                                borderRadius: theme.spacing(1),
                                boxShadow: theme.shadows[4],
                                justifyContent: 'center'
                            })}
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: user.color
                                }}
                            >
                                {user.firstName?.[0]}{user.lastName?.[0]}
                            </Avatar>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    mt: 2,
                                    mb: 3
                                }}
                            >
                                {user.fullName}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 0.5 }}>{user.email}</Typography>
                            <Typography variant="body2">{user.phone}</Typography>
                        </Stack>
                        <Stack sx={{ ml: 4 }}>
                            {answers.map((answer, key) =>
                                <Stack
                                    key={key}
                                    direction='row'
                                    sx={{
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: theme => theme.spacing(0.5),
                                        display: 'flex',
                                        p: 3,
                                        '&:not(:last-child)': {
                                            mb: 2
                                        }
                                    }}
                                >
                                    <CustomAvatar sx={{ alignSelf: 'center' }}>
                                        {key + 1}
                                    </CustomAvatar>
                                    <Stack ml={2}>
                                        <Typography
                                            sx={{
                                                color: grey[800],
                                                direction: t('dir'),
                                                textAlign: t('align'),
                                                width: theme => theme.spacing(69),
                                                mb: 1
                                            }}
                                        >
                                            {answer.question[t('questionDescriptionColumn')]}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: 'text.secondary',
                                                direction: t('dir'),
                                                textAlign: t('align'),
                                            }}
                                        >
                                            {answer.description}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ pt: 2, pb: 2, pr: 3 }}>
                        <Button
                            onClick={onNotVerify}
                            size="large"
                            color="error"
                            variant="outlined"
                            disabled={isVerifying || isNotVerifying}
                            startIcon={<Block />}
                        >
                            {isNotVerifying ? <CircularProgress size={26} /> : 'Not verified'}
                        </Button>
                        <Button
                            onClick={onVerify}
                            size="large"
                            color="primary"
                            variant="outlined"
                            disabled={isVerifying || isNotVerifying}
                            startIcon={<Done />}
                        >
                            {isVerifying ? <CircularProgress size={26} /> : 'Verified'}
                        </Button>
                    </DialogActions>
                </>
                :
                <>
                    <DialogContent sx={{ display: "flex", mt: 3 }}>
                        <Box
                            sx={theme => ({
                                width: theme.spacing(28),
                                height: theme.spacing(26),
                                alignItems: 'center',
                                color: theme.palette.grey[800],
                                borderRadius: theme.spacing(1),
                                boxShadow: theme.shadows[4]
                            })}
                        >
                            <Skeleton variant="circular" width={40} height={40} sx={{ mt: 3.5 }} />
                            <Skeleton width={128} sx={{ fontWeight: 'bold', mt: 2, mb: 3 }} />
                            <Skeleton width={180} sx={{ mb: 0.5 }} />
                            <Skeleton width={108} />
                        </Box>
                        <Box sx={{ ml: 4 }}>
                            {Array.from(new Array(2)).map((_, k) =>
                                <Box
                                    key={k}
                                    sx={{
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: theme => theme.spacing(0.5),
                                        display: 'flex',
                                        p: 3,
                                        '&:not(:last-child)': {
                                            mb: 2
                                        }
                                    }}>
                                    <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
                                    <Box>
                                        <Skeleton width={276} />
                                        <Skeleton width={84} sx={{ color: 'text.secondary' }} />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ pt: 2, pb: 2, pr: 3 }}>
                        <Skeleton variant="rectangular" width={96} height={42} />
                        <Skeleton variant="rectangular" width={96} height={42} />
                    </DialogActions>
                </>
            }
        </Dialog >
    );
};

export default AutenticateUserDialog;
