import { useAuth } from "../../context/Auth";

const Logout = () => {
    const { logout } = useAuth();

    logout();
    return null;
};

export default Logout;
