import React, { useRef, useState, useEffect } from 'react';
import { Tooltip, Typography } from '@mui/material';


const CuiOverflowTooltip = ({ children, ...props }) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const textElementRef = useRef();

    const compareSize = () => {
        if (textElementRef.current !== null) {
            const compare =
                textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
            setIsOverflow(compare);
        }
    };

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, [children]);

    // remove resize listener again on "componentWillUnmount"
    useEffect(
        () => () => {
            window.removeEventListener('resize', compareSize);
        }, []);

    return (
        <Tooltip
            title={props.title || children || ''}
            disableHoverListener={!isOverflowed}
            arrow
        >
            <Typography
                variant={props.variant || "body2"}
                gutterBottom
                ref={textElementRef}
                {...props}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ...props.sx
                }}
            >
                {children}
            </Typography>
        </Tooltip>
    );
};

export default CuiOverflowTooltip;
