import React from "react";
import { styled } from '@mui/material/styles';
import { IconButton, useTheme } from "@mui/material";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";


const PREFIX = 'TablePaginationActions';

const classes = {
    paginationActions: `${PREFIX}-paginationActions`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.paginationActions}`]: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5)
    }
}));

const TablePaginationActions = (props) => {

    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const onFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const onBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const onNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const onLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Root className={classes.paginationActions}>
            <IconButton
                onClick={onFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                size="large">
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={onBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                size="large">
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={onNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                size="large">
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={onLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                size="large">
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Root>
    );
};

export default TablePaginationActions;
