import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Notifications } from "@mui/icons-material";


const NotificationButton = ({ user, onClick }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!user.enableActions && isOpen) {
            setIsOpen(false);
        }
    }, [user.enableActions, isOpen]);

    var title = user.isInactive ? 'Only active employees can be reminded' : 'Send Reminder';

    return (
        <Tooltip
            title={title}
            arrow
            onOpen={_ => {
                if (user.enableActions) {
                    setIsOpen(true);
                }
            }}
            onClose={_ => setIsOpen(false)}
            open={isOpen}
        >
            <IconButton
                aria-label="send notification"
                onClick={(e) => onClick(e, user)}
                size="large"
                disabled={!user.enableActions || user.isInactive}
                sx={{
                    p: 0,
                    color: 'primary.light',
                    "&.Mui-disabled": {
                        pointerEvents: "auto"
                    }
                }}
            >
                {user.enableActions
                    ? <Notifications />
                    : <CircularProgress size={26} />
                }
            </IconButton>
        </Tooltip>
    )
}

export default NotificationButton;
