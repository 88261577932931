import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useDebouncedCallback } from 'use-debounce/lib';


const TableSearch = ({ placeholder, onChangeValue, value }) => {

  const debounced = useDebouncedCallback((debouncedText) => {
    onChangeValue(debouncedText)
  }, 0)

  return (
    <TextField
      value={value}
      variant="outlined"
      onChange={e => debounced(e.target.value)}
      placeholder={placeholder || 'Search'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        sx:{ height: theme => theme.spacing(4.5) }
      }}
    />
  );
}

export default TableSearch;
