import React from 'react';
import { styled } from '@mui/material/styles';
import { Tabs, Tab } from '@mui/material';


const PREFIX = 'TableFilter';

const classes = {
    tab: `${PREFIX}-tab`,
    selected: `${PREFIX}-selected`
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
    [`& .${classes.tab}`]: {
        textTransform: 'none',
        fontSize: theme.spacing(1.875),
        fontWeight: 550,
        height: theme.spacing(4),
        minHeight: theme.spacing(4),
        color: theme.palette.text.secondary,
        minWidth: 'fit-content',
        margin: theme.spacing(1),
        padding: theme.spacing(0, 2.5),
        [`&.${classes.selected}`]: {
            backgroundColor: '#d6eaff',
            borderRadius: theme.spacing(0.5),
            color: theme.palette.primary.main
        }
    }
}));

const TableFilter = ({ filters, value, onChange }) => {
    
    return (
        <StyledTabs
            value={value}
            onChange={onChange}
            aria-label="filter-tabs"
            TabIndicatorProps={{
                style: {
                    display: "none",
                }
            }}
        >
            {Object.entries(filters).map(([key, value]) =>
                <Tab
                    key={key}
                    label={value}
                    className={classes.tab}
                    classes={{
                        selected: classes.selected
                    }}
                />
            )}
        </StyledTabs>
    );
};

export default TableFilter;
