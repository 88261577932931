import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const PageTitle = ({ title }) => {
    return (
        <Box>
            <Typography variant="h5" color={grey[900]}>
                {title}
            </Typography>
        </Box>
    )
};

export default PageTitle;
