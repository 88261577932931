import React from "react";
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";
import ConfirmImage from "../../images/ConfirmImage";
import { grey } from "@mui/material/colors";

const PREFIX = 'ConfirmModal';

const classes = {
    dialogTitle: `${PREFIX}-dialogTitle`,
    dialogContent: `${PREFIX}-dialogContent`,
    title: `${PREFIX}-title`,
    content: `${PREFIX}-content`,
    dialogActions: `${PREFIX}-dialogActions`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogTitle}`]: {
        width: theme.spacing(75),
        minWidth: theme.spacing(75),
        height: theme.spacing(40),
        minHeight: theme.spacing(40),
        backgroundImage: `url("data:image/svg+xml,${svgString}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0
    },
    [`& .${classes.dialogContent}`]: {
        padding: theme.spacing(1, 3, 0),
        textAlign: 'center'
    },
    [`& .${classes.title}`]: {
        padding: theme.spacing(1, 0),
        color: grey[900]
    },
    [`& .${classes.content}`]: {
        color: theme.palette.text.secondary,
        paddingTop: theme.spacing(1)
    },
    [`& .${classes.dialogActions}`]: {
        padding: theme.spacing(2, 3, 4),
        justifyContent: 'center'
    }
}));

const svgString = encodeURIComponent(renderToStaticMarkup(<ConfirmImage />));

const ConfirmModal = ({ open, onCancel, onConfirm, disableActions }) => {

    return (
        <StyledDialog
            open={open}
            maxWidth="md"
        >
            <DialogTitle className={classes.dialogTitle} />
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h5" className={classes.title}>
                    Send a Reminder?
                </Typography>
                <Typography className={classes.content}>
                    Employees will be notified by email <br />
                    to fill out the security questions.
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    autoFocus
                    onClick={onCancel}
                    color="primary"
                    disabled={disableActions}
                >
                    Cancel
                </Button>
                <Button
                    autoFocus
                    variant="contained"
                    onClick={onConfirm}
                    color="primary"
                    disabled={disableActions}
                >
                    {disableActions ? <CircularProgress size={26} /> : 'Send'}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default ConfirmModal;
