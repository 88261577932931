import React from "react";

const Title = ({ fill }) => {
    return (
        <svg width="88" height="24" viewBox="0 0 88 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.97104 17.8199C6.6377 17.8199 5.42437 17.5066 4.33104 16.8799C3.25104 16.2399 2.39104 15.3733 1.75104 14.2799C1.12437 13.1733 0.811035 11.9533 0.811035 10.6199C0.811035 9.25992 1.1177 8.03326 1.73104 6.93992C2.34437 5.84659 3.18437 4.99326 4.25104 4.37992C5.3177 3.75326 6.5177 3.43992 7.85104 3.43992C8.57104 3.43992 9.2777 3.52659 9.97104 3.69992C10.6644 3.87326 11.2577 4.11992 11.751 4.43992L11.211 7.53992C10.251 7.17992 9.38437 6.99992 8.61104 6.99992C7.54437 6.99992 6.72437 7.31326 6.15104 7.93992C5.5777 8.55326 5.29104 9.43992 5.29104 10.5999C5.29104 11.7333 5.59104 12.6199 6.19104 13.2599C6.80437 13.8866 7.65104 14.1999 8.73104 14.1999C9.14437 14.1999 9.53104 14.1599 9.89104 14.0799C10.251 13.9999 10.691 13.8533 11.211 13.6399L11.771 16.7999C10.611 17.4799 9.34437 17.8199 7.97104 17.8199Z" fill={fill || "white"} />
            <path d="M16.9313 17.6599C15.8113 17.6599 14.938 17.2999 14.3113 16.5799C13.6847 15.8599 13.3713 14.8799 13.3713 13.6399V7.47992H17.6113V13.4599C17.6113 13.7666 17.6913 14.0066 17.8513 14.1799C18.0247 14.3399 18.2447 14.4199 18.5113 14.4199C18.778 14.4199 19.0513 14.3399 19.3313 14.1799L19.5513 16.9599C19.258 17.1866 18.8713 17.3599 18.3913 17.4799C17.9247 17.5999 17.438 17.6599 16.9313 17.6599ZM15.4913 5.97992C14.8647 5.97992 14.3313 5.78659 13.8913 5.39992C13.4647 4.99992 13.2513 4.51326 13.2513 3.93992C13.2513 3.36659 13.478 2.88659 13.9313 2.49992C14.3847 2.11326 14.9047 1.91992 15.4913 1.91992C16.1047 1.91992 16.6313 2.11992 17.0713 2.51992C17.5247 2.91992 17.7513 3.39326 17.7513 3.93992C17.7513 4.52659 17.5247 5.01326 17.0713 5.39992C16.618 5.78659 16.0913 5.97992 15.4913 5.97992Z" fill={fill || "white"} />
            <path d="M25.0312 17.6599C23.8579 17.6599 22.9445 17.3266 22.2912 16.6599C21.6379 15.9799 21.3112 15.0466 21.3112 13.8599V9.95992H20.0312V7.47992H21.6112L23.4712 3.69992H25.4912V7.47992H27.8112V9.95992H25.4912V13.3599C25.4912 13.6933 25.5979 13.9533 25.8112 14.1399C26.0245 14.3266 26.3245 14.4199 26.7112 14.4199C27.0712 14.4199 27.3979 14.3399 27.6912 14.1799L27.8512 16.9799C27.5179 17.1799 27.0845 17.3466 26.5512 17.4799C26.0312 17.5999 25.5245 17.6599 25.0312 17.6599Z" fill={fill || "white"} />
            <path d="M34.8126 22.0799C34.0793 22.0799 33.3793 22.0066 32.7126 21.8599L33.0326 18.8999C33.5526 18.9799 34.0326 19.0199 34.4726 19.0199C35.2593 19.0199 35.8593 18.7799 36.2726 18.2999C36.6859 17.8333 36.8993 16.9466 36.9126 15.6399C36.2193 16.9733 35.1059 17.6399 33.5726 17.6399C32.2793 17.6399 31.2659 17.1866 30.5326 16.2799C29.8126 15.3599 29.4526 14.0933 29.4526 12.4799V7.47992H33.6526V13.0999C33.6526 13.5533 33.7793 13.9333 34.0326 14.2399C34.2859 14.5333 34.6326 14.6799 35.0726 14.6799C35.5259 14.6799 35.8926 14.4866 36.1726 14.0999C36.4659 13.7133 36.6126 13.2066 36.6126 12.5799V7.47992H40.8326V14.9599C40.8326 17.2799 40.3126 19.0466 39.2726 20.2599C38.2326 21.4733 36.7459 22.0799 34.8126 22.0799Z" fill={fill || "white"} />
            <path d="M43.302 3.73992H48.262C50.3153 3.73992 51.902 4.13992 53.022 4.93992C54.1553 5.73992 54.722 6.85992 54.722 8.29992C54.722 9.20659 54.442 9.99992 53.882 10.6799C53.3353 11.3599 52.582 11.8866 51.622 12.2599C50.6753 12.6199 49.622 12.7999 48.462 12.7999H47.622V17.4999H43.302V3.73992ZM48.482 10.0199C49.1886 10.0199 49.7353 9.87326 50.122 9.57992C50.5086 9.28659 50.702 8.85992 50.702 8.29992C50.702 7.73992 50.5086 7.31992 50.122 7.03992C49.7486 6.75992 49.202 6.61992 48.482 6.61992H47.622V9.97992C47.8886 10.0066 48.1753 10.0199 48.482 10.0199Z" fill={fill || "white"} />
            <path d="M60.3845 17.6599C59.5178 17.6599 58.7045 17.4333 57.9445 16.9799C57.1978 16.5133 56.5978 15.8866 56.1445 15.0999C55.6911 14.2999 55.4645 13.4133 55.4645 12.4399C55.4645 11.4799 55.6911 10.6133 56.1445 9.83992C56.5978 9.05326 57.2045 8.43992 57.9645 7.99992C58.7245 7.55992 59.5311 7.33992 60.3845 7.33992C61.0778 7.33992 61.7178 7.53326 62.3045 7.91992C62.9045 8.30659 63.3578 8.88659 63.6645 9.65992V7.33992H67.8645V17.4999H63.6645V15.6399C62.9978 16.9866 61.9045 17.6599 60.3845 17.6599ZM61.6845 14.4999C62.2178 14.4999 62.6711 14.3133 63.0445 13.9399C63.4311 13.5533 63.6378 13.0999 63.6645 12.5799V12.4199C63.6378 11.8866 63.4245 11.4333 63.0245 11.0599C62.6378 10.6866 62.1911 10.4999 61.6845 10.4999C61.1245 10.4999 60.6445 10.6999 60.2445 11.0999C59.8578 11.4866 59.6645 11.9533 59.6645 12.4999C59.6645 13.0599 59.8645 13.5333 60.2645 13.9199C60.6645 14.3066 61.1378 14.4999 61.6845 14.4999Z" fill={fill || "white"} />
            <path d="M73.5003 17.6599C72.1003 17.6599 70.8736 17.4533 69.8203 17.0399L70.2803 14.6599C71.2403 15.0066 72.1736 15.1799 73.0803 15.1799C73.7469 15.1799 74.0803 14.9933 74.0803 14.6199C74.0803 14.4333 73.9803 14.2799 73.7803 14.1599C73.5936 14.0399 73.2336 13.9333 72.7003 13.8399C71.5936 13.6266 70.7936 13.2666 70.3003 12.7599C69.8203 12.2533 69.5803 11.5333 69.5803 10.5999C69.6336 9.54659 70.0536 8.73992 70.8403 8.17992C71.6269 7.61992 72.7002 7.33992 74.0602 7.33992C74.7536 7.33992 75.3469 7.38659 75.8403 7.47992C76.3469 7.55992 76.8069 7.69992 77.2203 7.89992L76.8003 10.2799C76.4136 10.1333 75.9869 10.0199 75.5203 9.93992C75.0669 9.85992 74.6869 9.81992 74.3803 9.81992C73.9269 9.81992 73.6136 9.86659 73.4403 9.95992C73.2669 10.0533 73.1803 10.1799 73.1803 10.3399C73.1803 10.6333 73.5136 10.8399 74.1803 10.9599C75.3936 11.1733 76.3003 11.5466 76.9003 12.0799C77.5003 12.6133 77.8003 13.3399 77.8003 14.2599C77.8003 15.3133 77.4269 16.1466 76.6803 16.7599C75.9469 17.3599 74.8869 17.6599 73.5003 17.6599Z" fill={fill || "white"} />
            <path d="M82.8885 17.6599C81.4885 17.6599 80.2619 17.4533 79.2085 17.0399L79.6685 14.6599C80.6285 15.0066 81.5619 15.1799 82.4685 15.1799C83.1352 15.1799 83.4685 14.9933 83.4685 14.6199C83.4685 14.4333 83.3685 14.2799 83.1685 14.1599C82.9819 14.0399 82.6219 13.9333 82.0885 13.8399C80.9819 13.6266 80.1819 13.2666 79.6885 12.7599C79.2085 12.2533 78.9685 11.5333 78.9685 10.5999C79.0219 9.54659 79.4419 8.73992 80.2285 8.17992C81.0152 7.61992 82.0885 7.33992 83.4485 7.33992C84.1419 7.33992 84.7352 7.38659 85.2285 7.47992C85.7352 7.55992 86.1952 7.69992 86.6085 7.89992L86.1885 10.2799C85.8019 10.1333 85.3752 10.0199 84.9085 9.93992C84.4552 9.85992 84.0752 9.81992 83.7685 9.81992C83.3152 9.81992 83.0019 9.86659 82.8285 9.95992C82.6552 10.0533 82.5685 10.1799 82.5685 10.3399C82.5685 10.6333 82.9019 10.8399 83.5685 10.9599C84.7819 11.1733 85.6885 11.5466 86.2885 12.0799C86.8885 12.6133 87.1885 13.3399 87.1885 14.2599C87.1885 15.3133 86.8152 16.1466 86.0685 16.7599C85.3352 17.3599 84.2752 17.6599 82.8885 17.6599Z" fill={fill || "white"} />
        </svg>
    )
};

export default Title;