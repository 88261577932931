import React from "react";
import { styled } from '@mui/material/styles';
import { Avatar, Box, Grid, Switch, Tooltip, Typography } from "@mui/material";
import MuiIconButton from "@mui/material/IconButton"
import CustomAvatar from "../custom/CuiAvatar";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import classNames from "classnames";
import { grey } from "@mui/material/colors";
import { withStyles } from "@mui/styles";


const PREFIX = 'QuestionCard';

const classes = {
    root: `${PREFIX}-root`,
    disabledCard: `${PREFIX}-disabledCard`,
    firstDisabledCard: `${PREFIX}-firstDisabledCard`,
    disabledAvatar: `${PREFIX}-disabledAvatar`,
    iconButton: `${PREFIX}-iconButton`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: theme.spacing(3.5, 5),
        boxShadow: theme.shadows[8],
        borderRadius: theme.spacing(1),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    [`&.${classes.disabledCard}`]: {
        backgroundColor: grey[200]
    },
    [`&.${classes.firstDisabledCard}`]: {
        marginTop: theme.spacing(8.5)
    },
    [`& .${classes.disabledAvatar}`]: {
        backgroundColor: theme.palette.action.disabledBackground,
        opacity: theme.palette.action.disabledOpacity
    },
    [`& .${classes.iconButton}`]: {
        padding: theme.spacing(1),
        margin: theme.spacing(0, 1)
    }
}));

const IconButton = withStyles({
    root: {
        "&.Mui-disabled": {
            pointerEvents: "auto"
        }
    }
})(MuiIconButton);

const DisabledIconButton = ({ tooltipTitle, onClick, ...other }) => {
    const adjustedButtonProps = {
        disabled: true,
        component: "div"
    };
    return (
        <Tooltip title={tooltipTitle} arrow>
            <IconButton
                {...other}
                {...adjustedButtonProps}
                size="large"
                classes={{
                    root: classes.root
                }} />
        </Tooltip>
    );
};

const QuestionCard = ({ question, index, onEdit, onDelete, onChangeSwitch, isAdminUser }) => {

    const enabled = question.isEnabled;

    return (
        <StyledBox className={classNames(
            classes.root,
            !enabled && classes.disabledCard,
            !enabled && !index && classes.firstDisabledCard
        )}>
            <Grid container>
                <Grid item xs={9} container>
                    <Grid item xs={1}>
                        {enabled
                            ? <CustomAvatar>{index + 1}</CustomAvatar>
                            : <Avatar className={classes.disabledAvatar}>{""}</Avatar>
                        }
                    </Grid>
                    <Grid item xs={5} container display="flex" alignItems="center">
                        <Typography sx={{ color: grey[800] }}>{question.description}</Typography>
                    </Grid>
                    <Grid item xs={1} container display="flex" alignContent={"start"} sx={{ color: grey[500], fontWeight: 550 }}>
                        {'.'}
                    </Grid>
                    <Grid item xs={3} container display="flex" alignItems="center" justifyContent={"right"}>
                        <Typography sx={{ color: grey[800], direction: "rtl" }}>{question.heDescription}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                    {isAdminUser &&
                        <Box className={classes.actions}>
                            <Switch
                                checked={enabled}
                                onChange={(e) => onChangeSwitch(e.target.checked)}
                                color="primary"
                            />
                            {question.hasAnswers ?
                                <DisabledIconButton
                                    tooltipTitle="Only unused questions can be edited"
                                    className={classes.iconButton}
                                >
                                    <EditOutlined color="disabled" />
                                </DisabledIconButton>
                                :
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={onEdit}
                                    size="large"
                                    classes={{
                                        root: classes.root
                                    }}>
                                    <EditOutlined color="primary" />
                                </IconButton>
                            }
                            {question.hasAnswers ?
                                <DisabledIconButton
                                    tooltipTitle="Only unused questions can be deleted"
                                    className={classes.iconButton}
                                >
                                    <DeleteOutline color="disabled" />
                                </DisabledIconButton>
                                :
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={onDelete}
                                    size="large"
                                    classes={{
                                        root: classes.root
                                    }}>
                                    <DeleteOutline color="primary" />
                                </IconButton>
                            }
                        </Box>
                    }
                </Grid>
            </Grid>
        </StyledBox>
    );
};

export default QuestionCard;
