import React from "react";
import { styled } from '@mui/material/styles';
import { Backdrop, Box, Card, CardContent } from "@mui/material";
import LoginButton from "./LoginButton";


const PREFIX = 'LoginPopup';

const classes = {
    card: `${PREFIX}-card`,
    backdrop: `${PREFIX}-backdrop`,
    submit: `${PREFIX}-submit`
};

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    [`&.${classes.backdrop}`]: {
        position: 'absolute',
        zIndex: theme.zIndex.modal + 1
    },
    [`& .${classes.card}`]: {
        position: 'relative',
        overflow: 'auto'
    },
    [`& .${classes.submit}`]: {
        padding: theme.spacing(1, 4),
        marginTop: theme.spacing(3)
    }
}));

const LoginPopup = () => {


    return (
        <StyledBackdrop open={true} className={classes.backdrop}>
            <Card variant="outlined" className={classes.card}>
                <CardContent>
                    <Box justifyContent="space-around">
                        <Box>Your session is ended.</Box>
                    </Box>
                    <LoginButton variant="contained" />
                </CardContent>
            </Card>
        </StyledBackdrop>
    );
};

export default LoginPopup;