const config = {
    authConfig: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID || '',
        authority: process.env.REACT_APP_AAD_AUTHORITY || '',
        redirectUrl: process.env.REACT_APP_AAD_REDIRECT_URL || '',
        apiScope: process.env.REACT_APP_AAD_API_SCOPE || ''
    },
    apiUrl: process.env.REACT_APP_API || '',
    edmApiUrl: process.env.REACT_APP_EDM_API || '',
    roles: {
        admin: process.env.REACT_APP_ADMIN_ROLE || 'ITAdmin',
        member: process.env.REACT_APP_MEMBER_ROLE || 'ITUsers'
    }
}

export default config;
