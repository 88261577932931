import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import TableSearch from './CuiTableSearch';
import { getComparator, tableSort } from './CuiTableSort';
import TableFilter from './CuiTableFilter';
import PageTitle from '../CuiPageTitle';
import classNames from 'classnames';
import TablePaginationActions from './CuiTablePaginationActions';
import CuiOverflowTooltip from '../CuiOverflowTooltip';
import { grey } from '@mui/material/colors';


const PREFIX = 'EnhancedTable';

const classes = {
    header: `${PREFIX}-header`,
    tableContainer: `${PREFIX}-tableContainer`,
    table: `${PREFIX}-table`,
    tableArea: `${PREFIX}-tableArea`,
    selected: `${PREFIX}-selected`,
    visuallyHidden: `${PREFIX}-visuallyHidden`,
    tableHeader: `${PREFIX}-tableHeader`,
    checkboxCell: `${PREFIX}-checkboxCell`,
    headerCheckbox: `${PREFIX}-headerCheckbox`,
    selectedRowsNum: `${PREFIX}-selectedRowsNum`,
    tableCell: `${PREFIX}-tableCell`,
    fixedTableCell: `${PREFIX}-fixedTableCell`,
    checkbox: `${PREFIX}-checkbox`,
    footer: `${PREFIX}-footer`,
    tablePagination: `${PREFIX}-tablePagination`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.header}`]: {
        marginBottom: theme.spacing(3.5),
        '& > div': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    [`& .${classes.tableContainer}`]: {
        '&::-webkit-scrollbar': {
            width: '0.4em',
            background: grey[100],
            borderRadius: theme.spacing(0.5)
        },
        '&::-webkit-scrollbar-track': {
            'WebkitBoxShadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: grey[400],
            borderRadius: theme.spacing(0.5)
        }
    },
    [`& .${classes.table}`]: {
        minWidth: 50,
        tableLayout: 'fixed'
    },
    [`& .${classes.tableArea}`]: {
        borderRadius: theme.spacing(0.5),
        boxShadow: theme.shadows[16]
    },
    [`& .${classes.selected}`]: {
        '&$selected, &$selected:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    [`& .${classes.tableHeader}`]: {
        lineHeight: '1.4rem',
        height: theme.spacing(3),
        fontWeight: 600
    },
    [`& .${classes.checkboxCell}`]: {
        width: theme.spacing(9.25)
    },
    [`& .${classes.headerCheckbox}`]: {
        marginLeft: theme.spacing(1.5)
    },
    [`& .${classes.selectedRowsNum}`]: {
        width: 'fit-content',
        marginLeft: theme.spacing(2),
        fontWeight: 500
    },
    [`& .${classes.tableCell}`]: {
        height: theme.spacing(2.5),
        maxWidth: 0
    },
    [`& .${classes.fixedTableCell}`]: {
        paddingBottom: theme.spacing(1.5)
    },
    [`& .${classes.checkbox}`]: {
        marginLeft: theme.spacing(1.5)
    },
    [`& .${classes.footer}`]: {
        alignItems: "center",
        height: theme.spacing(9)
    },
    [`& .${classes.tablePagination}`]: {
        borderBottomWidth: 0
    }
}));

const EnhancedTable = ({
    title,
    columns,
    rows,
    keyColumn,
    defaultOrderByColumn,
    selectedKeys,
    checkboxColumn,
    disableCheckboxColumn,
    hasPaging,
    defaultRowsPerPage,
    searchColumns,
    filterColumn,
    filters,
    onRowsViewChange,
    onRowSelect,
    headerActionButton,
    checkboxFilterLabel,
    checkboxFilterColumn,
    onCheckboxFilterChange,
    maxHeight
}) => {

    const [page, setPage] = useState(0);

    const defaultRowPerPageOptions = [10, 25, 50];

    const [rowsPerPage, setRowsPerPage] = useState(() => {
        if (!hasPaging) return -1;
        return (defaultRowsPerPage && defaultRowPerPageOptions.find(o => o === defaultRowsPerPage || o.value === defaultRowsPerPage))
            || defaultRowPerPageOptions[0];
    });
    const [searchValue, setSearchValue] = useState('');
    const [filterValue, setFilterValue] = useState(0);
    const [filterChecked, setFilterChecked] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(defaultOrderByColumn || columns[0].id || columns[0].key);
    const selected = Array.isArray(selectedKeys)
        ? selectedKeys
        : selectedKeys
            ? [selectedKeys]
            : [];

    const getDescendantProp = (row, path) => {
        if (!path) return row
        return path.split('.').reduce((acc, part) => acc && acc[part], row)
    }

    const isValueExistInRow = (row) => {
        const even = (column) => {
            let prop = getDescendantProp(row, column);
            return (
                prop !== undefined &&
                prop.toString().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            );
        };
        return even;
    };

    const filteredRows = () => {
        let filtered = rows;
        if (searchValue)
            filtered = filtered.filter((row) => {
                return searchColumns && searchColumns.some(isValueExistInRow(row))
            });
        if (filterColumn && filterValue > 0)
            filtered = filtered.filter(row => row[filterColumn] === filters[filterValue])
        if (checkboxFilterColumn)
            filtered = filtered.filter(row => filterChecked || !row[checkboxFilterColumn]);
        return filtered;
    }

    const onColumnSort = (colId) => () => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
        setOrderBy(colId);
    };

    const onRowCheck = (row) => {
        if (keyColumn) {
            const key = row[keyColumn];
            const selectedIndex = selected.indexOf(key);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, key);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }
            onRowSelect(newSelected);
        }
    };

    const showSelectAllText = _ => {
        const totalLength = filteredRows().length;
        return selected.length === totalLength && page < Math.ceil(totalLength / rowsPerPage) - 1;
    }

    const onSelectAllClick = (event) => {
        if (event.target.checked && keyColumn && selected.length === 0) {
            const newSelected = filteredRows()
                .filter(row => disableCheckboxColumn ? !row[disableCheckboxColumn] : true)
                .map(n => n[keyColumn]);
            onRowSelect(newSelected);
        }
        else {
            onRowSelect([]);
        }
    }

    const onPageChange = (event, newPage) => {
        setPage(newPage);
        onRowsViewChange();
    };

    const onRowsPerPageChange = (event) => {
        const rows = parseInt(event.target.value, 10);
        setRowsPerPage(rows);
        setPage(0);
        onRowsViewChange();
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <StyledBox>
            <Grid container className={classes.header} >
                <Grid item xs={1}>
                    {title &&
                        <PageTitle title={title} />
                    }
                </Grid>
                <Grid item xs={checkboxFilterLabel ? 3 : 6} sx={{ pl: 3 }}>
                    {filters && (
                        <TableFilter
                            filters={filters}
                            value={filterValue}
                            onChange={(_, newValue) => {
                                setFilterValue(newValue);
                                setPage(0);
                                onRowSelect([]);
                                onRowsViewChange();
                                setSearchValue('');
                            }}
                        />
                    )}
                </Grid>
                {checkboxFilterLabel &&
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={filterChecked}
                                    onChange={_ => {
                                        setFilterChecked(!filterChecked);
                                        onCheckboxFilterChange && onCheckboxFilterChange(!filterChecked)
                                    }}
                                />}
                            label={`${checkboxFilterLabel}${filterChecked ? ` (${filteredRows()?.filter(row => row[checkboxFilterColumn])?.length})` : ''}`}
                            sx={theme => ({
                                [theme.breakpoints.up('lg')]: {
                                    ml: 2
                                },
                                [theme.breakpoints.down('lg')]: {
                                    ml: 5
                                },
                            })}
                        />
                    </Grid>
                }
                <Grid item xs={3} container justifyContent="flex-end">
                    {headerActionButton && headerActionButton()}
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                    {searchColumns && (
                        <TableSearch
                            value={searchValue}
                            onChangeValue={newValue => {
                                setPage(0);
                                setSearchValue(newValue);
                                onRowsViewChange();
                            }}
                            placeholder={"Search"}
                        />
                    )}
                </Grid>
            </Grid>
            <Box className={classes.tableArea}>
                <TableContainer
                    className={classes.tableContainer}
                    sx={{ maxHeight: maxHeight, minHeight: maxHeight }}
                >
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-label="enhanced table"
                        sx={{ position: 'relative' }}
                    >
                        <TableHead>
                            <TableRow>
                                {checkboxColumn && (
                                    <TableCell
                                        padding="checkbox"
                                        sx={{ fontWeight: 'bold' }}
                                        className={classNames(classes.tableHeader, classes.checkboxCell)}
                                    >
                                        <Checkbox
                                            indeterminate={
                                                selected.length > 0 &&
                                                selected.length < filteredRows().length
                                            }
                                            checked={
                                                filteredRows().length > 0 &&
                                                selected.length === filteredRows().length
                                            }
                                            onChange={onSelectAllClick}
                                            color={'primary'}
                                            inputProps={{ 'aria-label': 'select all ' + selected.length }}
                                            className={classes.headerCheckbox}
                                        />
                                    </TableCell>
                                )}
                                {columns.map(column => {
                                    let columnKey = column.id || column.key;
                                    return (
                                        <TableCell
                                            key={columnKey}
                                            className={classes.tableHeader}
                                            align={column.align}
                                            width={column.width}
                                            sx={{ display: column.display || 'table-cell' }}
                                        >
                                            {!column.isSort ? column.label
                                                : (
                                                    <TableSortLabel
                                                        active={orderBy === columnKey}
                                                        direction={orderBy === columnKey ? order : 'asc'}
                                                        onClick={onColumnSort(columnKey)}
                                                    >
                                                        {column.label}
                                                        {orderBy === columnKey ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {order === 'desc'
                                                                    ? 'sorted descending'
                                                                    : 'sorted ascending'}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                )}
                                        </TableCell>
                                    )
                                }
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {tableSort(
                                    filteredRows(),
                                    getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        rowsPerPage === -1
                                            ? filteredRows().length
                                            : page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                classes={{ selected: classes.selected }}
                                                tabIndex={-1}
                                                selected={isSelected(keyColumn && row[keyColumn])}
                                            >
                                                {checkboxColumn && (
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isSelected(keyColumn && row[keyColumn])}
                                                            color={'primary'}
                                                            className={classes.checkbox}
                                                            onClick={event => {
                                                                event.stopPropagation();
                                                                event.preventDefault();
                                                                onRowCheck && onRowCheck(row);
                                                            }}
                                                            disabled={disableCheckboxColumn && row[disableCheckboxColumn]}
                                                        />
                                                    </TableCell>
                                                )}
                                                {columns.map(column => {
                                                    let value = getDescendantProp(row, column.id);
                                                    let columnKey = column.id || column.key;
                                                    return (
                                                        <TableCell
                                                            key={columnKey}
                                                            align={column.align}
                                                            className={classNames(
                                                                classes.tableCell,
                                                                column.component && classes.fixedTableCell
                                                            )}
                                                            sx={{ display: column.display || 'table-cell' }}
                                                        >
                                                            {column.component ?
                                                                column.component(value) :
                                                                column.isText ?
                                                                    <CuiOverflowTooltip>{value}</CuiOverflowTooltip> :
                                                                    value}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                            </>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack direction="row" justifyContent="space-between" className={classes.footer}>
                    <Box>
                        {selected.length > 0 &&
                            <Typography className={classes.selectedRowsNum}>
                                {selected.length} rows selected {showSelectAllText() && 'in all pages'}
                            </Typography>
                        }
                    </Box>
                    {hasPaging && (
                        <TablePagination
                            component="div"
                            className={classes.tablePagination}
                            rowsPerPageOptions={defaultRowPerPageOptions}
                            count={filteredRows().length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                            }}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}
                            ActionsComponent={TablePaginationActions}
                        />
                    )}
                </Stack>
            </Box>
        </StyledBox>
    );
};

export default EnhancedTable;
