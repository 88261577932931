import React from "react";
import { Chip } from "@mui/material";

const CuiChip = ({ label, color = 'primary' }) => {
    return (
        <Chip
            label={label}
            size="small"
            variant="filled"
            color={color}
            sx={{
                borderRadius: 1,
                backgroundColor: `${color}.lightBg`,
                "& .MuiChip-label": {
                    color: `${color}.dark`
                }
            }}
        />
    );
};

export default CuiChip;
