import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, Box, Menu, MenuItem, Button, Tabs, Tab, Grid } from '@mui/material';
import { ArrowDropDown } from "@mui/icons-material";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import SecurityIcon from "../../images/SecurityIcon";
import Title from "../../images/TitleIcon";
import WelcomeModal from "../login/WelcomeModal";
import { useCurrentUser } from "../../context/CurrentUser";


const navigations = [
    { text: 'Employees', to: '/employees' },
    { text: 'Questions', to: '/questions' },
    { text: 'Permissions', to: '/permissions' }
]

const HeaderNavbar = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [value, setValue] = useState(0);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const { user, login, logout } = useAuth();
    const { currentUser } = useCurrentUser();
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';

    useEffect(() => {
        let hideWelcomeModal = localStorage.getItem('hideWelcomeModal');
        if (!hideWelcomeModal) {
            setShowWelcomeModal(true);
            localStorage.setItem('hideWelcomeModal', '1');
        }
    }, [location.pathname]);

    useEffect(_ => {
        let index = navigations.findIndex(n => n.to === location.pathname);
        setValue(index);
    }, [location.pathname]);

    useEffect(_ => {
        setAnchorEl(null);
    }, [user])

    const onUserButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const onLoginClick = () => {
        login();
        setAnchorEl(null);
    }

    const onLogoutClick = () => {
        logout();
        setAnchorEl(null);
    }

    const onCloseMenu = () => {
        setAnchorEl(null);
    }

    const onNavigate = (_, newValue) => {
        setValue(newValue);
    }

    return (
        <div>
            <AppBar
                position="fixed"
                sx={theme => ({
                    ...(isLoginPage ?
                        {
                            backgroundColor: theme.palette.primary.contrastText
                        } :
                        {
                            backgroundColor: theme.palette.custom.background
                        }),
                    boxShadow: 'none'
                })}
            >
                <Toolbar>
                    <Grid container>
                        <Grid item xs={2} container display="flex" alignItems="center">
                            <SecurityIcon size={24} />
                            <Box
                                sx={{
                                    display: "inline-flex",
                                    ml: 2
                                }}
                            >
                                <Title fill={isLoginPage && '#2C4FEC'} />
                            </Box>
                        </Grid>
                        <Grid item xs={8} container display="flex" alignItems="center" justifyContent="center">
                            {user && currentUser &&
                                <Tabs
                                    value={value}
                                    onChange={onNavigate}
                                    sx={{
                                        ml: 7.75
                                    }}
                                    aria-label="navigation-tabs"
                                    TabIndicatorProps={{
                                        style: {
                                            display: "none",
                                        }
                                    }}>
                                    {Object.entries(navigations).map(([key, value]) =>
                                        <Tab
                                            key={key}
                                            label={value.text}
                                            to={value.to}
                                            component={Link}
                                            sx={theme => ({
                                                textTransform: 'none',
                                                fontSize: theme.spacing(1.875),
                                                height: theme.spacing(5),
                                                color: theme.palette.primary.contrastText,
                                                minWidth: 'fit-content',
                                                m: 1,
                                                p: theme.spacing(0, 2.5),
                                                '&.Mui-selected': {
                                                    backgroundColor: 'rgba(32, 238, 179, 0.2)',
                                                    borderRadius: theme.spacing(0.5),
                                                    color: 'rgba(32, 238, 179, 1)'
                                                }
                                            })}

                                        />
                                    )}
                                </Tabs>
                            }
                        </Grid>
                        <Grid item xs={2} container display="flex" alignItems="center" justifyContent="flex-end">
                            {user ?
                                <Box>
                                    <Button
                                        sx={{
                                            color: theme => theme.palette.primary.contrastText
                                        }}
                                        onClick={onUserButtonClick}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: theme => theme.spacing(1.875),
                                                mr: 1,
                                                letterSpacing: '0.46px'
                                            }}
                                        >
                                            Hi, {user?.givenName}!
                                        </Typography>
                                        <ArrowDropDown />
                                    </Button>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={onCloseMenu}
                                    >
                                        <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                                    </Menu>
                                </Box>
                                :
                                <MenuItem
                                    onClick={onLoginClick}
                                    sx={({
                                        ...(isLoginPage && {
                                            color: theme => theme.palette.custom.background
                                        }),
                                        fontWeight: 600
                                    })}
                                >
                                    Login
                                </MenuItem>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {showWelcomeModal && <WelcomeModal />}
        </div>
    );
}

export default HeaderNavbar;
