import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router';
import config from '../../config';
import { useAuth } from '../../context/Auth';
import { useCurrentUser } from '../../context/CurrentUser';
import Loader from '../custom/CuiLoader';
import AccessDenied from '../login/AccessDenied';
import LoginPopup from '../login/LoginPopup';

const ProtectedRoute = ({ children, ...rest }) => {
    const { isAuth, isMsalAuth, shouldLogout, roles, fetchWithUser } = useAuth();
    const { setCurrentUser } = useCurrentUser();
    const [hasRoles, setHasRoles] = useState();
    const [isRegistered, setIsRegistered] = useState();

    useEffect(_ => {
        var allowedRoles = Object.values(config.roles);
        setHasRoles(roles?.some(r => allowedRoles.find(a => a === r)));
    }, [roles]);

    useEffect(_ => {
        isAuth &&
            (async () => {
                await fetchWithUser(config.apiUrl + '/Users/GetCurrentUser')
                    .then(res => res.json())
                    .then(user => {
                        setIsRegistered(user !== null);
                    })
                    .catch(_ => {
                        setIsRegistered(false);
                    })
            })();
    }, [isAuth, fetchWithUser]);

    useEffect(_ => {
        if (typeof isRegistered === 'boolean' && typeof hasRoles === 'boolean')
            setCurrentUser(hasRoles && isRegistered);
    }, [setCurrentUser, hasRoles, isRegistered]);

    const components = children ? (
        <Route {...rest}>{children}</Route>
    ) : (
        <Route {...rest} />
    );

    if (shouldLogout)
        return <LoginPopup />;

    if (isAuth) {
        if (isRegistered && hasRoles)
            return components;
        if (isRegistered === false || hasRoles === false) {
            return <AccessDenied />;
        }
        else return <Loader />;
    }

    if (isMsalAuth) {
        return <Loader />
    }

    return (
        <Redirect
            to={{
                pathname: '/login',
                state: { from: rest.location }
            }}
        />
    )
};

export default ProtectedRoute;
