import i18n from "i18next";
import { initReactI18next } from 'react-i18next';


// the translations
const resources = {
    en: {
        translation:
        {
            "questionDescriptionColumn": "description",
            "dir": "ltr",
            "align": "start"
        }
    },
    he: {
        translation: {
            "questionDescriptionColumn": "heDescription",
            "dir": "rtl",
            "align": "end"
        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
