import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField
} from "@mui/material";


const PREFIX = 'QuestionDialog';

const classes = {
    textField: `${PREFIX}-textField`,
    dialogActions: `${PREFIX}-dialogActions`,
    button: `${PREFIX}-button`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.textField}`]: {
        width: theme.spacing(69)
    },
    [`& .${classes.dialogActions}`]: {
        padding: theme.spacing(2, 3)
    },
    [`& .${classes.button}`]: {
        marginTop: theme.spacing(1)
    }
}));

const QuestionDialog = ({ dialogData, isSaving }) => {

    const { title, open, onSave, onCancel, question } = dialogData;
    const [currentQuestion, setCurrentQuestion] = useState();
    useEffect(() => {
        setCurrentQuestion({
            english: {
                description: question?.description,
                isValid: true
            },
            hebrew: {
                description: question?.heDescription,
                isValid: true
            }
        })
    }, [question?.description, question?.heDescription]);
    const isValidForm = currentQuestion && Object.values(currentQuestion).every(q => q.description?.length > 0 && q.isValid);

    const resetForm = _ => {
        setCurrentQuestion({
            english: {
                description: '',
                isValid: true
            },
            hebrew: {
                description: '',
                isValid: true
            }
        })
    }

    const onQuestionChanged = (description, fieldName) => {
        let x = { ...currentQuestion };
        x[fieldName] = {
            description,
            isValid: !(description.trim() === '')
        };
        setCurrentQuestion(x);
    }

    const onSubmitQuestion = () => {
        onSave(
            {
                description: currentQuestion.english.description,
                heDescription: currentQuestion.hebrew.description
            },
            resetForm);
    }

    return (
        <StyledDialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onCancel();
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 550 }}>{title}</DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <FormControl variant="outlined" margin="dense">
                            <TextField
                                label="Enter a question in English"
                                defaultValue={currentQuestion?.english.description}
                                variant="outlined"
                                fullWidth
                                required
                                error={!currentQuestion?.english.isValid}
                                helperText={!currentQuestion?.english.isValid && "Required"}
                                disabled={isSaving}
                                autoFocus
                                onChange={(e) => onQuestionChanged(e.target.value, "english")}
                                className={classes.textField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined">
                            <TextField
                                label="Enter a question in Hebrew"
                                defaultValue={currentQuestion?.hebrew.description}
                                dir="rtl"
                                variant="outlined"
                                fullWidth
                                required
                                error={!currentQuestion?.hebrew.isValid}
                                helperText={!currentQuestion?.hebrew.isValid && "Required"}
                                disabled={isSaving}
                                onChange={(e) => onQuestionChanged(e.target.value, "hebrew")}
                                className={classes.textField}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={_ => {
                        onCancel();
                        resetForm();
                    }}
                    color="primary"
                    size="large"
                    className={classes.button}
                    disabled={isSaving}
                >
                    Cancel
                </Button>
                <Button
                    onClick={onSubmitQuestion}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    disabled={!isValidForm || isSaving}
                >
                    {isSaving ? <CircularProgress size={26} /> : 'Save'}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default QuestionDialog;
