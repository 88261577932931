import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import HeaderNavbar from "./components/navigation/HeaderNavbar";
import Loader from "./components/custom/CuiLoader";
import Login from "./components/login/LoginPage";
import Logout from "./components/login/Logout";
import ProtectedRoute from "./components/navigation/ProtectedRoute";
import Styles from "./context/Styles";
import Questions from "./components/questions/QuestionsPage";
import Employees from "./components/employees/EmployeesPage";
import { AuthContextProvider } from "./context/Auth";
import Permissions from "./components/permissions/Permissions";
import AccessDenied from "./components/login/AccessDenied";
import { CurrentUserContextProvider } from "./context/CurrentUser";

const App = () => {

  return (
    <div className="App">
      <Styles>
        <AuthContextProvider>
          <BrowserRouter>
            <CurrentUserContextProvider>
              <HeaderNavbar />
              <Switch>
                <ProtectedRoute path="/employees">
                  <Suspense fallback={<Loader />}>
                    <Employees />
                  </Suspense>
                </ProtectedRoute>
                <ProtectedRoute path="/questions">
                  <Suspense fallback={<Loader />}>
                    <Questions />
                  </Suspense>
                </ProtectedRoute>
                <ProtectedRoute path="/permissions">
                  <Suspense fallback={<Loader />}>
                    <Permissions />
                  </Suspense>
                </ProtectedRoute>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/logout">
                  <Logout />
                </Route>
                <Route path="/access-denied">
                  <AccessDenied />
                </Route>
                <Redirect exact from="/" to="/employees" />
              </Switch>
            </CurrentUserContextProvider>
          </BrowserRouter>
        </AuthContextProvider>
      </Styles>
    </div>
  );
}

export default App;
