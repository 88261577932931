import React from "react";
import MainContainer from "../custom/CuiMainContainer";
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Cancel, CheckCircle } from "@mui/icons-material";


const StyledTableHead = ({ children, ...props }) => {
    return (
        <TableCell
            sx={{
                color: grey[900],
                border: 'none',
                fontWeight: 700
            }}
            {...props}
        >
            {children}
        </TableCell>
    );
}

const StyledTableCell = ({ children, ...props }) => {
    return (
        <TableCell
            sx={{
                color: grey[900],
                border: 'none'
            }}
            {...props}
        >
            {children}
        </TableCell>
    );
}

const Permissions = () => {
    const permissions = [
        {
            name: "Send Reminder",
            enableItMember: false,
            enableAdmin: true
        },
        {
            name: "Verify employee",
            enableItMember: true,
            enableAdmin: true
        },
        {
            name: "Create / edit / delete questions",
            enableItMember: false,
            enableAdmin: true
        },
        {
            name: "View question list",
            enableItMember: true,
            enableAdmin: true
        }
    ];

    return (
        <MainContainer parent={true}>
            <MainContainer title='Permissions' child={true} xs={6}>
                <Box>
                    <Typography
                        color={grey[900]}
                        display='inline'
                    >
                        CityPass permissions are based on what is set in Azure AD.<br />
                        Below are the permissions for each role:
                    </Typography>
                </Box>
                <TableContainer
                    component={Paper}
                    sx={{
                        mt: 5,
                        boxShadow: '0px 11px 14px -7px rgba(0, 0, 0, 0.05), 0px 23px 36px 3px rgba(0, 0, 0, 0.035), 0px 6px 44px 19px rgba(0, 0, 0, 0.03);'
                    }}
                >
                    <Table aria-label="permissions table">
                        <TableHead>
                            <TableRow>
                                <StyledTableHead>Feature</StyledTableHead>
                                <StyledTableHead align="center">IT Member</StyledTableHead>
                                <StyledTableHead align="center">Admin</StyledTableHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {permissions.map((permission) => (
                                <TableRow key={permission.name}>
                                    <StyledTableCell>
                                        {permission.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {permission.enableItMember
                                            ? <CheckCircle color="secondary" sx={{ fontSize: 28 }} />
                                            : <Cancel
                                                sx={{
                                                    fontSize: 28,
                                                    color: grey[400]
                                                }}
                                            />
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {permission.enableAdmin && <CheckCircle color="secondary" sx={{ fontSize: 28 }} />}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </MainContainer>
            <Grid item xs={1} />
            <MainContainer title='How to add a new employee?' child={true} xs={5}>
                <Grid item xs={10} xl={8}>
                    <Typography>
                        Once adding a new employee to Azure AD he will be displayed on the employee list screen automatically and he will receive an email to fill out two security questions.
                    </Typography>
                </Grid>
            </MainContainer>
        </MainContainer>
    );
};

export default Permissions;
