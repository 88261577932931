import { Block, NotificationsNone, VerifiedUserOutlined } from "@mui/icons-material";

const ActivityTypes = [
    {
        id: 0,
        action: 'Employee verified',
        icon: <VerifiedUserOutlined />
    },
    {
        id: 1,
        action: 'Reminder Sent',
        icon: <NotificationsNone />
    },
    {
        id: 2,
        action: 'Employee not verified',
        icon: <Block />,
        color: "error"
    }
];

export default ActivityTypes;
