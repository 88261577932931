import React from "react";

const SecurityIcon = ({ size, style }) => {
    return (
        <svg
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path d="M16 32L16 16L32 16C32 24.8366 24.8366 32 16 32Z" fill="#2983DE" />
            <path d="M0 16L16 16L16 32C7.16344 32 0 24.8366 0 16Z" fill="#21FCBE" />
            <path d="M32 0L32 16L16 16L16 -1.58956e-06L32 0Z" fill="#2948D3" />
            <path d="M16 0L16 16L-1.58956e-06 16L0 -1.58956e-06L16 0Z" fill="#2983DE" />
        </svg>
    )
};

export default SecurityIcon;
