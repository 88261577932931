export const getDescendantProp = (row, path) => {
    if (!path) return row;
    return path.split('.').reduce((acc, part) => acc && acc[part], row);
}

export const descendingComparator = (
    a,
    b,
    orderBy,
    caseSensitive = false
) => {
    let aData = getDescendantProp(a, orderBy);
    if (!caseSensitive && typeof aData === 'string') aData = aData.toLowerCase();
    let bData = getDescendantProp(b, orderBy);
    if (!caseSensitive && typeof bData === 'string') bData = bData.toLowerCase();

    return bData === null || bData < aData
        ? -1
        : aData === null || bData > aData
            ? 1
            : 0;
}

export const getComparator = (
    order,
    orderBy
) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const tableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    })
    return stabilizedThis.map(el => el[0]);
}
