import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import config from "../../config";
import { Box, Button, IconButton, Snackbar } from "@mui/material";
import EnhancedTable from "../custom/table/CuiTable";
import { useAuth } from "../../context/Auth";
import UserActivity from "./UserActivity";
import AutenticateUserDialog from "./AutenticateUserDialog";
import Colors from "./Colors";
import { Close, Error, Notifications } from "@mui/icons-material";
import MainContainer from "../custom/CuiMainContainer";
import { format, addDays } from "date-fns";
import ConfirmModal from "./ConfirmSendEmail";
import { getLocalDateFromUtc } from "../../utils/formatDate";
import Loader from "../custom/CuiLoader";
import NotificationButton from "./NotificationButton";
import CuiChip from "../custom/CuiChip";
import ActivityTypes from "./ActivityTypes";
import AuthenticateButton from "./AuthenticateButton";
import UserStatuses from "./UserStatuses";
import CuiOverflowTooltip from "../custom/CuiOverflowTooltip";
import * as util from 'util';


const PREFIX = 'Users';

const classes = {
    snackbar: `${PREFIX}-snackbar`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.snackbar}`]: {
        top: theme.spacing(9)
    }
}));

const Users = () => {

    const [users, setUsers] = useState(null);
    const [departments, setDepartments] = useState(null);
    const [usersData, setUsersData] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const defaultAuthenticateModalData = {
        open: false,
        user: null,
        isVerifying: false,
        isNotVerifying: false
    };
    const [authenticateModalData, setAuthenticateModalData] = useState(defaultAuthenticateModalData);
    const defaultActivityData = {
        open: false,
        activity: null,
        anchorEl: null,
        selectedAnchorKey: null
    };
    const [activityPopperData, setActivityPopperData] = useState(defaultActivityData);
    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [showInactiveUsers, setShowInactiveUsers] = useState(false);
    const showInactiveUsers = false;
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [isSendingNotification, setIsSendingNotification] = useState(false);
    const { fetchWithUser, roles } = useAuth();
    const [isAdmin, setIsAdmin] = useState(false);


    useEffect(_ => {
        setIsAdmin(roles?.find(r => r === config.roles.admin));
    }, [roles])


    const InactiveChip = (isInactive) => {
        return isInactive &&
            <Box>
                <Error
                    color="warning"
                    sx={{
                        verticalAlign: 'bottom',
                        mr: 1
                    }}
                />
                <CuiChip
                    label="Inactive"
                    color="warning"
                />
            </Box>
    }

    const StatusChip = (status) => {
        return <CuiChip
            label={status}
            color={["info", "secondary"][UserStatuses.indexOf(status)]}
        />
    }

    const LastActivity = (activity) => {
        var sorted = activity.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const formatDate = date => format(new Date(getLocalDateFromUtc(date)), 'dd/MM/yyyy hh:mm a');
        var key = sorted[0]?.userId;
        var activityType = ActivityTypes[activity[0]?.typeId];
        return (activity.length > 0 &&
            <>
                <Button
                    size="small"
                    color={activityType?.color || 'primary'}
                    sx={theme => ({
                        display: 'flex',
                        alignItems: 'center',
                        width: '-webkit-fill-available',
                        color: theme.palette.text.primary,
                        backgroundColor: key === activityPopperData.selectedAnchorKey ?
                            activityType?.color ? theme.palette[activityType.color].lightBg : 'info.lightBg' : 'default',
                        '& .MuiButton-startIcon': {
                            color: activityType?.color ? theme.palette[activityType.color].light : "info.light"
                        }
                    })}
                    onClick={e => toggleOpenPopper(e, sorted)}
                    startIcon={activityType?.icon}
                >
                    <CuiOverflowTooltip
                        variant="button"
                        sx={{
                            fontSize: '13px',
                            mb: 0
                        }}
                    >
                        {activity[0] && formatDate(activity[0].createdAt)}
                    </CuiOverflowTooltip>
                </Button>
                {activityPopperData.activity && key === activityPopperData.selectedAnchorKey &&
                    <UserActivity data={activityPopperData} />
                }
            </>
        );
    }

    const toggleOpenPopper = (event, activity) => {
        event.stopPropagation();
        event.preventDefault();
        var open = activityPopperData.anchorEl !== event.currentTarget || !activityPopperData.open
        setActivityPopperData({
            open: open,
            onClose: _ => setActivityPopperData(defaultActivityData),
            activity: activity,
            anchorEl: event.currentTarget,
            selectedAnchorKey: open ? activity[0]?.userId : null
        });
    }

    const onRowsViewChange = () => {
        setActivityPopperData(defaultActivityData);
    }

    const sendNotification = (selected, isMultiUsers = false) => {
        if (isMultiUsers)
            setIsSendingNotification(true);
        const options = {
            method: 'POST',
            body: JSON.stringify(selected.map(u => u.id)),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        fetchWithUser(`${config.apiUrl}/Notifications/Send`, options)
            .then(response => response.json())
            .then((activities) => {
                if (!isMultiUsers)
                    setSnackbarMessage(`Email notification successfully sent to ${selected[0].fullName}`);
                setOpenSnackbar(true);
                let currentUsers = [...users];
                activities.forEach(a => currentUsers.find(u => u.id === a.userId)?.activities.push(a))
                setUsers(currentUsers);
            })
            .catch((e) => {
                console.log(e);
                setSnackbarMessage('Oooops! something went wrong, please try later');
                setOpenSnackbar(true);
            })
            .finally(_ => {
                if (isMultiUsers) {
                    setOpenConfirmModal(false);
                    setIsSendingNotification(false);
                }
                else {
                    var updated = [...users];
                    updated.find(u => u.id === selected[0].id).enableActions = true;
                    setUsers(updated);
                }
            });
    }

    const notifyUser = (event, user) => {
        event.stopPropagation();
        event.preventDefault();
        var updated = [...users];
        updated.find(u => u.id === user.id).enableActions = false;
        setUsers(updated);
        sendNotification([user], false);
    }

    const notifySelectedUsers = () => {
        let selected = users.filter(u => selectedUsers.includes(u.id));
        setSnackbarMessage(`Email notification successfully sent to ${selectedUsers.length} employee${selectedUsers.length > 1 ? 's' : ''}`);
        sendNotification(selected, true);
    }

    const NotifySelectedUsersButton = () => {
        return (
            isAdmin && selectedUsers.length > 0 &&
            <Button
                color="primary"
                sx={{ fontWeight: 'bold' }}
                startIcon={<Notifications sx={{ color: 'primary.light' }} />}
                onClick={_ => setOpenConfirmModal(true)}
            >
                Send Reminder
            </Button>
        )
    }

    const onCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    const updateUserActivity = (activity) => {
        const currUsers = [...users];
        currUsers.find(u => u.id === activity.userId).activities.push(activity);
        setUsers(currUsers);
    }

    const addActivity = (userId, verified) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                "userId": userId,
                "typeId": verified ? 0 : 2
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        setAuthenticateModalData({ ...authenticateModalData, isVerifying: verified, isNotVerifying: !verified });
        fetchWithUser(config.apiUrl + '/Activity', options)
            .then(response => response.json())
            .then((data) => {
                setSnackbarMessage(verified ? 'The employee has been successfully verified' : 'The employee has not been verified');
                setAuthenticateModalData(defaultAuthenticateModalData);
                updateUserActivity(data);
            })
            .catch(() => {
                setSnackbarMessage('Oooops! something went wrong, please try later')
                setAuthenticateModalData(prev => ({
                    ...prev,
                    isVerifying: false,
                    isNotVerifying: false
                }))
            })
            .finally(() => {
                setOpenSnackbar(true);
            });
    }

    const onNotVerify = () => {
        addActivity(authenticateModalData.user.id, false);
    }

    const onVerify = () => {
        addActivity(authenticateModalData.user.id, true);
    }

    const getRelatedQuestions = _ => {
        return answers?.filter(a => a.userID === authenticateModalData.user.id)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .slice(0, 2);
    }

    const openUserAuthModal = (event, user) => {
        event.stopPropagation();
        event.preventDefault();
        setAuthenticateModalData({
            user,
            open: true,
            isVerifying: false,
            isNotVerifying: false
        });
    }

    const getColor = (k) => Colors[k % Colors.length];

    useEffect(() => {
        (async () => {
            await fetchWithUser(config.apiUrl + '/Users')
                .then(res => res.json())
                .then(async (data) => {
                    Object.values(data).forEach((u, k) => {
                        u.fullName = u.firstName + ' ' + u.lastName;
                        u.status = UserStatuses[u.statusId];
                        u.color = getColor(k);
                        u.enableActions = true;
                        u.departments = '';
                        u.isInactive = !u.isActive;
                    });
                    setUsersData(data);
                })
                .catch(err => {
                    console.log(err);
                })
        })();
    }, [fetchWithUser, setUsersData]);

    useEffect(_ => {
        let hrDepartments = JSON.parse(localStorage.getItem('hrDepartments'));
        if (!hrDepartments || new Date().getTime() > hrDepartments.expiry) {
            (async () => {
                await fetch(util.format(config.edmApiUrl, 'GetHRDepartment'))
                    .then(res => res.json())
                    .then(data => {
                        setDepartments(data);
                        localStorage.setItem('hrDepartments', JSON.stringify({
                            value: data,
                            expiry: addDays(new Date(), 1).getTime()
                        }));
                    })
                    .catch(err => console.log(err));
            })();
        }
        else {
            setDepartments(hrDepartments.value);
        }
    }, []);

    useEffect(_ => {
        if (departments && usersData) {
            setUsers(usersData.map(u => ({
                ...u,
                departments: [u.mainDepartment, u.secondaryDepartment, u.thirdDepartment]
                    .filter(Boolean)
                    .map(d => departments.find(de => de.id === d)?.name)
                    .filter(Boolean)
                    .join(', ')
            })));
            setIsLoading(false);
        }
    }, [departments, usersData]);

    useEffect(() => {
        (async () => {
            await fetchWithUser(config.apiUrl + '/Answers')
                .then(res => res.json())
                .then(data => {
                    setAnswers(data);
                })
        })();
    }, [fetchWithUser]);

    const columns = [
        {
            id: 'id',
            label: 'ID',
            isSort: true,
            isText: true,
            width: 80
        },
        {
            id: 'fullName',
            label: 'Name',
            isSort: true,
            isText: true
        },
        {
            id: 'isInactive',
            component: isInactive => InactiveChip(isInactive),
            display: showInactiveUsers ? 'table-cell' : 'none',
        },
        {
            id: 'email',
            label: 'Email',
            isSort: true,
            isText: true
        },
        {
            id: 'departments',
            label: 'Departments',
            isSort: true,
            isText: true
        },
        {
            id: 'status',
            label: 'Status',
            isSort: true,
            component: status => StatusChip(status),
            align: 'center'
        },
        {
            id: 'activities',
            label: 'Last Activity',
            component: activity => LastActivity(activity)
        },
        {
            key: 'notification',
            label: '',
            component: user =>
                <NotificationButton
                    user={user}
                    onClick={e => {
                        onRowsViewChange();
                        notifyUser(e, user);
                    }}
                />,
            align: 'right',
            width: 32,
            display: isAdmin ? 'table-cell' : 'none'
        },
        {
            key: 'authentication',
            label: '',
            component: user =>
                <AuthenticateButton
                    user={user}
                    onClick={e => {
                        onRowsViewChange();
                        openUserAuthModal(e, user);
                    }}
                />,
            align: 'right',
            width: 32
        }
    ];

    return (
        <Root>
            {isLoading ? <Loader />
                : <MainContainer>
                    <EnhancedTable
                        title={"Employees"}
                        columns={columns}
                        rows={users}
                        keyColumn={'id'}
                        defaultOrderByColumn={'fullName'}
                        selectedKeys={selectedUsers}
                        checkboxColumn={true}
                        disableCheckboxColumn={'isInactive'}
                        hasPaging={true}
                        defaultRowsPerPage={10}
                        searchColumns={["id", "fullName", "email", "departments"]}
                        filterColumn={"status"}
                        filters={["All"].concat(UserStatuses)}
                        onRowsViewChange={onRowsViewChange}
                        onRowSelect={selected => {
                            setSelectedUsers(selected);
                            onRowsViewChange();
                        }}
                        headerActionButton={NotifySelectedUsersButton}
                        // checkboxFilterLabel={"Display inactive employees"}
                        // checkboxFilterColumn={'isInactive'}
                        // onCheckboxFilterChange={checked => setShowInactiveUsers(checked)}
                        maxHeight={"60vh"}
                    />
                </MainContainer>
            }
            {authenticateModalData.user &&
                <AutenticateUserDialog
                    open={authenticateModalData.open}
                    onClose={_ => setAuthenticateModalData(defaultAuthenticateModalData)}
                    onVerify={onVerify}
                    onNotVerify={onNotVerify}
                    user={authenticateModalData.user}
                    answers={authenticateModalData.user && getRelatedQuestions()}
                    isVerifying={authenticateModalData.isVerifying}
                    isNotVerifying={authenticateModalData.isNotVerifying}
                />
            }
            <ConfirmModal
                open={openConfirmModal}
                onCancel={_ => setOpenConfirmModal(false)}
                onConfirm={notifySelectedUsers}
                disableActions={isSendingNotification}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                onClose={onCloseSnackbar}
                message={snackbarMessage}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={onCloseSnackbar}>
                        <Close fontSize="small" />
                    </IconButton>}
                autoHideDuration={3000}
                className={classes.snackbar}
            />
        </Root>
    );
}

export default Users;