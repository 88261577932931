const Colors = [
    "#00C875", //Green
    "#4ECCC6", //Aquamarine
    "#7E3B8A", //Berry
    "#FAA1F1", //Bubble
    "#66CCFF", //Chill blue
    "#401694", //dark indigo
    "#784BD1", //Dark purple
    "#FFCB00", //Egg yolk
    "#5559DF", //Indigo_purple
    "#579BFC", //Light blue
    "#225091", //navy
    "#FDAB3D", //Orange
    "#FFADAD", //peach
    "#68A1BD", //River_blue
    "#225091", //Sky blue
    "#FF7575", //Sunset_red
    "#9AADBD", //Winter_grey
];

export default Colors;