import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Backdrop, Button, CircularProgress, IconButton, Snackbar } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import config from "../../config";
import { useAuth } from "../../context/Auth";
import MainContainer from "../custom/CuiMainContainer";
import QuestionCard from "./QuestionCard";
import QuestionDialog from "./QuestionDialog";
import ConfirmDeleteQuestion from "./ConfirmDeleteQuestion";
import Loader from "../custom/CuiLoader";
import { getLocalDateFromUtc } from "../../utils/formatDate";


const PREFIX = 'Questions';

const classes = {
    snackbar: `${PREFIX}-snackbar`,
    backdrop: `${PREFIX}-backdrop`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.snackbar}`]: {
        bottom: theme.spacing(9)
    },
    [`& .${classes.backdrop}`]: {
        zIndex: theme.zIndex.modal,
        backgroundColor: 'rgba(255,255,255,0.4)'
    }
}));

const Questions = () => {

    const [questions, setQuestions] = useState(null);
    const defaultDialogData = {
        open: false,
        title: '',
        onCancel: () => setDialogData(defaultDialogData)
    }
    const [dialogData, setDialogData] = useState(defaultDialogData);
    const [snackbarData, setSnackbarData] = useState({
        open: false,
        message: '',
        onClose: () => setSnackbarData({
            ...snackbarData,
            open: false
        })
    });
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const { roles, fetchWithUser } = useAuth();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(_ => {
        setIsAdmin(roles?.find(r => r === config.roles.admin));
    }, [roles])

    useEffect(() => {
        (async () => {
            await fetchWithUser(config.apiUrl + '/Question')
                .then(res => res.json())
                .then(data => {
                    Object.values(data).forEach((q) => {
                        q.hasAnswers = q.answers.length > 0;
                        q.updatedAt = getLocalDateFromUtc(q.updatedAt);
                    });
                    setQuestions(data);
                })
                .catch(err => {
                    console.log(err);
                })
        })();
    }, [fetchWithUser, setQuestions]);

    const showErrorMessage = _ => {
        setSnackbarData({
            ...snackbarData,
            open: true,
            message: 'Oooops! something went wrong, please try later'
        });
    }

    const onEditQuestion = (question, message, showLoader = false) => {
        const options = {
            method: 'PUT',
            body: JSON.stringify(question),
            headers: {
                'Content-Type': 'application/json'
            }
        }

        showLoader && setLoading(true);
        setIsSaving(true);
        fetchWithUser(`${config.apiUrl}/Question/${question.id}`, options)
            .then((response) => response.json())
            .then(result => {
                let newQuestions = [...questions];
                let updated = newQuestions.find(q => q.id === question.id);
                updated.id = result.id;
                updated.description = result.description;
                updated.heDescription = result.heDescription;
                updated.updatedAt = getLocalDateFromUtc(result.updatedAt);
                updated.isEnabled = result.isEnabled;
                setQuestions(newQuestions);
                setDialogData(defaultDialogData);
                setSnackbarData({
                    ...snackbarData,
                    open: true,
                    message: message
                });
            })
            .catch(err => {
                console.log(err);
                showErrorMessage();
            })
            .finally(_ => {
                showLoader && setLoading(false);
                setIsSaving(false);
            })
    }

    const editQuestion = (question) => {
        setDialogData({
            ...dialogData,
            open: true,
            title: 'Edit Question',
            question: question,
            onSave: (newQuestion) => {
                var updated = { ...question };
                updated.description = newQuestion.description;
                updated.heDescription = newQuestion.heDescription;
                onEditQuestion(updated, 'The question was saved successfully');
            }
        });
    }

    const confirmDeleteQuestion = (question) => {
        setCurrentQuestion(question);
        setOpenConfirmDialog(true);
    }

    const onDeleteQuestion = () => {
        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }

        setLoading(true);
        fetchWithUser(`${config.apiUrl}/Question/${currentQuestion.id}`, options)
            .then(_ => {
                setSnackbarData({
                    ...snackbarData,
                    open: true,
                    message: 'The question was deleted successfully'
                });
                setQuestions(questions.filter(q => q.id !== currentQuestion.id));
            })
            .catch(_ => showErrorMessage())
            .finally(_ => {
                setOpenConfirmDialog(false);
                setLoading(false);
            })
    }

    const onCreateQuestion = (newQuestion, resetForm) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({ ...newQuestion, "isEnabled": true }),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        setIsSaving(true);
        fetchWithUser(config.apiUrl + '/Question', options)
            .then(response => response.json())
            .then((data) => {
                setDialogData(defaultDialogData);
                setSnackbarData({
                    ...snackbarData,
                    open: true,
                    message: 'The question was added successfully'
                });
                data.updatedAt = getLocalDateFromUtc(data.updatedAt);
                setQuestions([...questions, data]);
                resetForm();
            })
            .catch(_ => showErrorMessage())
            .finally(_ => {
                setIsSaving(false);
            })
    }

    const addQuestion = () => {
        setDialogData({
            ...dialogData,
            open: true,
            title: 'New Question',
            onSave: (newQuestion, resetForm) => onCreateQuestion(newQuestion, resetForm)
        });
    }

    const onChangeMode = (question, enabled) => {
        var updated = { ...question };
        updated.isEnabled = enabled;
        onEditQuestion(updated, `One question is ${enabled ? 'active' : 'inactive'}`, true);
    }

    const AddQuestionButton = () => {
        if (isAdmin)
            return (
                <Button
                    color="primary"
                    size="large"
                    startIcon={<Add />}
                    className={classes.button}
                    onClick={addQuestion}
                >
                    New Question
                </Button>
            )
    }

    return (
        questions ?
            <MainContainer
                title='Questions'
                titleActions={_ => AddQuestionButton()}
            >
                <Root>
                    {questions && questions
                        .filter(q => q.isEnabled)
                        .sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))
                        .map((question, key) =>
                            <QuestionCard
                                key={key}
                                question={question}
                                index={key}
                                onEdit={() => editQuestion(question)}
                                onDelete={() => confirmDeleteQuestion(question)}
                                onChangeSwitch={(mode) => onChangeMode(question, mode)}
                                isAdminUser={isAdmin}
                            />
                        )}
                    {questions && questions
                        .filter(q => !q.isEnabled)
                        .sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))
                        .map((question, key) =>
                            <QuestionCard
                                key={key}
                                question={question}
                                index={key}
                                onEdit={() => editQuestion(question)}
                                onDelete={() => confirmDeleteQuestion(question)}
                                onChangeSwitch={(mode) => onChangeMode(question, mode)}
                                isAdminUser={isAdmin}
                            />
                        )}
                    <QuestionDialog dialogData={dialogData} isSaving={isSaving} />
                    <ConfirmDeleteQuestion
                        open={openConfirmDialog}
                        onCancel={_ => {
                            setOpenConfirmDialog(false);
                            setCurrentQuestion(null);
                        }
                        }
                        onConfirm={_ => onDeleteQuestion()}
                        loading={loading}
                    />
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={snackbarData.open}
                        onClose={snackbarData.onClose}
                        message={snackbarData.message}
                        action={
                            <IconButton size="small" aria-label="close" color="inherit" onClick={snackbarData.onClose}>
                                <Close fontSize="small" />
                            </IconButton>}
                        autoHideDuration={3000}
                        className={classes.snackbar}
                    />
                    <Backdrop
                        open={loading}
                        className={classes.backdrop}
                    >
                        <CircularProgress color="primary" />
                    </Backdrop>
                </Root>
            </MainContainer>
            : <Loader />
    );
}

export default Questions;