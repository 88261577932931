import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { VerifiedUser } from "@mui/icons-material";
import UserStatuses from "./UserStatuses";


const AuthenticateButton = ({ user, onClick }) => {
    var enabled = !user.isInactive && user.status === UserStatuses[1];
    var title = user.isInactive ? 'Only active employees can be verified' :
        user.status === UserStatuses[1] ? 'Verify Employee' : 'Only employees with "completed" status can be verified.';

    return (
        <Tooltip
            title={title}
            arrow
        >
            <IconButton
                aria-label="Verify Employee"
                onClick={(e) => { return enabled ? onClick(e, user) : null }}
                size="large"
                component={enabled ? "button" : "div"}
                disabled={!enabled}
                sx={{
                    p: 0,
                    color: 'primary.light',
                    "&.Mui-disabled": {
                        pointerEvents: "auto"
                    }
                }}
            >
                <VerifiedUser />
            </IconButton>
        </Tooltip>
    )
};

export default AuthenticateButton;
