import React from "react";
import { Grid } from "@mui/material";
import PageTitle from "./CuiPageTitle";


const MainContainer = (props) => {

    return (
        <Grid
            item={props.child || undefined}
            container
            justifyContent="space-around"
            xs={props.xs || undefined}
            sx={theme => ({
                ...(!props.child && {
                    [theme.breakpoints.down('xl')]: {
                        mt: 16
                    },
                    [theme.breakpoints.up('xl')]: {
                        mt: 19
                    }
                })
            })}
        >
            <Grid
                item
                container={props.parent}
                xs={11}
            >
                {props.title && (
                    <Grid
                        container
                        display="flex"
                        alignItems="center"
                        mb={3.5}
                    >
                        <Grid item xs={8} xl={6}>
                            <PageTitle title={props.title} />
                        </Grid>
                        <Grid item xs={4} xl={6} container justifyContent="flex-end">
                            {props.titleActions && props.titleActions()}
                        </Grid>
                    </Grid>
                )}
                {props.children}
            </Grid>
        </Grid>
    );
};

export default MainContainer;
