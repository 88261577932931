import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";


const ConfirmDeleteQuestion = ({ open, onCancel, onConfirm, loading }) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    fontWeight: 550
                }}
            >
                Delete Question?
            </DialogTitle>
            <DialogContent>
                Notice, you will not be able to recover it.
            </DialogContent>
            <DialogActions
                sx={{
                    p: theme => theme.spacing(2, 3)
                }}>
                <Button
                    onClick={onCancel}
                    disabled={loading}
                >
                    Cancel
                </Button>
                <Button
                    onClick={onConfirm}
                    autoFocus
                    variant="contained"
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={26} /> : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteQuestion;
