const get = async (endpoint, options = {}) => {
    const mergedOpts = { ...options, method: 'GET' };
    return await fetcher(endpoint, mergedOpts);
}

const getJson = async (endpoint, options = {}) => {
    const mergedOpts = { ...options, method: 'GET' };
    const res = await fetcher(endpoint, mergedOpts);
    return await res.json();
}

const post = async (endpoint, options = {}) => {
    const mergedOpts = {
        ...options,
        method: 'POST'
    };
    return await fetcher(endpoint, mergedOpts);
}

const put = async (endpoint, options = {}) => {
    const mergedOpts = {
        ...options,
        method: 'PUT'
    };
    return await fetcher(endpoint, mergedOpts);
}

const del = async (endpoint, options = {}) => {
    const mergedOpts = {
        ...options,
        method: 'DELETE'
    };
    return await fetcher(endpoint, mergedOpts);
}

const use = (interceptor) => {
    interceptors.push(interceptor);
}

const interceptors = [];

use(options => {
    return Promise.resolve({
        ...options,
        headers: {
            ...options.headers,
            'Content-Type': 'application/json'
        }
    });
})

const fetcher = async (endpoint, options) => {
    let mergedOpts = { ...options };
    for (let i = 0; i < interceptors.length; i++) {
        mergedOpts = {
            ...mergedOpts,
            ...(await interceptors[i]({ ...mergedOpts }))
        };
    }

    return fetch(endpoint, mergedOpts)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.status);
            }
            return response;
        });
}

const fetcherMethods = {
    get,
    getJson,
    post,
    put,
    del,
    fetch: fetcher,
    use
};

export default fetcherMethods;