import React from 'react';

const MicrosoftIcon = () => {
    return (
        <svg
            viewBox="0 0 16 16"
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.5 7.5H16V0.5C16 0.224 15.776 0 15.5 0H8.5V7.5Z"
                fill="#4CAF50"
            />
            <path d="M7.5 7.5V0H0.5C0.224 0 0 0.224 0 0.5V7.5H7.5Z" fill="#F44336" />
            <path
                d="M7.5 8.5H0V15.5C0 15.776 0.224 16 0.5 16H7.5V8.5Z"
                fill="#2196F3"
            />
            <path
                d="M8.5 8.5V16H15.5C15.776 16 16 15.776 16 15.5V8.5H8.5Z"
                fill="#FFC107"
            />
        </svg>
    )
};

export default MicrosoftIcon;
