import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
    Button,
    ClickAwayListener,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    Popper,
    Stack,
    Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { format } from "date-fns";
import { getLocalDateFromUtc } from "../../utils/formatDate";
import { grey } from "@mui/material/colors";
import ActivityTypes from "./ActivityTypes";


const PREFIX = 'UserActivity';

const classes = {
    paper: `${PREFIX}-paper`,
    popper: `${PREFIX}-popper`,
    list: `${PREFIX}-list`,
    showMore: `${PREFIX}-showMore`
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    [`&.${classes.popper}`]: {
        zIndex: 1
    },
    [`& .${classes.paper}`]: {
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow: '0px 11px 14px -7px rgba(0, 0, 0, 0.05), 0px 23px 36px 3px rgba(0, 0, 0, 0.035), 0px 6px 44px 19px rgba(0, 0, 0, 0.03)',
        filter: 'drop-shadow(0px -14px 58px rgba(0, 0, 0, 0.07))',
        padding: theme.spacing(1, 0)
    },
    [`& .${classes.list}`]: {
        overflow: 'auto',
        maxHeight: theme.spacing(44),
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(0, 3, 0, 1),
        '&::-webkit-scrollbar': {
            width: '0.4em',
            background: grey[100],
            borderRadius: theme.spacing(0.5)
        },
        '&::-webkit-scrollbar-track': {
            'WebkitBoxShadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: grey[400],
            borderRadius: theme.spacing(0.5)
        }
    },
    [`& .${classes.showMore}`]: {
        color: theme.palette.primary.dark,
        fontSize: theme.spacing(2),
        marginLeft: theme.spacing(2)
    }
}));

const formatDate = (date) => format(new Date(getLocalDateFromUtc(date)), 'dd/MM/yyyy hh:mm a');

const ActivityItem = ({ activity }) => {
    var type = ActivityTypes[activity.typeId];
    return (
        <ListItem sx={{ pr: 3 }}>
            <ListItemIcon
                sx={{
                    color: theme => type?.color ? theme.palette[type.color].light : 'info.light',
                    minWidth: 0,
                    mr: 2
                }}
            >
                {type?.icon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Stack>
                        <Stack
                            direction={"row"}
                            sx={{ color: theme => type?.color ? theme.palette[type.color].main : '' }}
                        >
                            <Typography variant="subtitle2">{type?.action} by&nbsp;</Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: 'bold' }}
                            >
                                {activity.creatorName || 'CityPass'}
                            </Typography>
                        </Stack>
                        <Typography variant="subtitle2" color={grey[500]}>
                            {formatDate(activity.createdAt)}
                        </Typography>
                    </Stack>
                }
            />
        </ListItem >)
};

const UserActivity = ({ data }) => {
    const { activity, open, onClose, anchorEl } = data;

    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        setShowMore(false);
    }, [activity])

    const toggleShowMore = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowMore(!showMore);
    }

    return (
        <ClickAwayListener onClickAway={onClose}>
            <StyledPopper
                open={open}
                anchorEl={anchorEl}
                placement='bottom'
                disablePortal={true}
                className={classes.popper}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                            altBoundary: true,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: false,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: 'viewport',
                            padding: 8,
                            boundariesElement: 'scrollParent'
                        },
                    }
                ]}
            >
                <Paper className={classes.paper}>
                    <List className={classes.list}>
                        <ListSubheader sx={{ pr: 0 }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "text.primary"
                                    }}
                                >
                                    Activity History
                                </Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={onClose}
                                    sx={{ mr: -1 }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </Stack>
                        </ListSubheader>
                        {activity
                            .slice(0, 4)
                            .map((a, k) =>
                                <ActivityItem activity={a} key={k} />
                            )
                        }
                        {showMore &&
                            activity
                                .slice(4)
                                .map((a, k) =>
                                    <ActivityItem activity={a} key={k} />
                                )
                        }
                    </List>
                    {activity.length > 4 &&
                        <Button
                            size="small"
                            onClick={(e) => toggleShowMore(e)}
                            className={classes.showMore}
                        >
                            {showMore ? 'Show less' : 'Show more'}
                        </Button>
                    }
                </Paper>
            </StyledPopper>
        </ClickAwayListener>
    );
};

export default UserActivity;
