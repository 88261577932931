import React from "react";
import { styled } from '@mui/material/styles';
import { Avatar } from "@mui/material";


const PREFIX = 'CustomAvatar';

const classes = {
    avatar: `${PREFIX}-avatar`
};

const StyledAvatar = styled(Avatar)(({ theme }
) => ({
    [`&.${classes.avatar}`]: {
        backgroundColor: theme.palette.primary.background,
        color: theme.palette.primary.main,
    }
}));

const CustomAvatar = ({ children, ...props }) => {

    return (
        <StyledAvatar className={classes.avatar} {...props}>
            {children}
        </StyledAvatar>
    );
};

export default CustomAvatar;
